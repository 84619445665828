// import { Mod, state, log, api, Cmp, Atom } from ":mods";
import { actions as RouteActions, hooks as RouteHooks } from "../../atoms/route/store";
import { actions as AlertActions } from "../../components/alert/store";
import { actions as PopupActions } from "../../components/popup/store";

//? creating a portal of what is allowed to be done by children of all routes through controlled actions, hooks..etc.
export const hooks = {
  ...RouteHooks,
};

export const actions = {
  ...AlertActions,
  ...PopupActions,
  ...RouteActions,
};
